/* TableWithDualCalendarList.css */
.unverified-dates-list {
  margin-top: 20px;
}

.unverified-dates-list h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.unverified-dates-list ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.unverified-dates-list li {
  font-size: 1rem;
  margin-bottom: 5px;
}
