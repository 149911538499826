/* TableWithDualCalendar.css */
.react-date-range .rdrDateRangeWrapper {
  display: flex;
  flex-direction: row;
}

.react-date-range .rdrDateDisplayWrapper {
  flex: 1;
}

.react-date-range .rdrMonthAndYearWrapper,
.react-date-range .rdrDateDisplay {
  width: 100%;
}

.react-date-range .rdrDateRangePickerInput,
.react-date-range .rdrDateRangePickerInput input {
  width: 100%;
}

.react-date-range .rdrDateRangePickerInputRange {
  width: 100%;
}

.react-date-range .rdrDateRangePickerInput__inputGroup {
  width: 100%;
}

.react-date-range .rdrStaticRange {
  width: 100%;
}
