@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

.numberClass::-webkit-outer-spin-button,
.numberClass::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

.scrollbar-hide {
    -ms-overflow-style: none; 
    scrollbar-width: none; 
}

.shrinkToTwoLines{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis
}

.shrinkToOneLine{
-webkit-line-clamp: 1 !important;
}

/* Hide scrollbar for Chrome, Safari, and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
body {
  scrollbar-width: none;
}

/* Add this to your CSS stylesheet or use inline styles in the component */
.react-datepicker-calendar .highlighted {
  background-color: red;
  color: white;
}

.scrolled {
  transform: translateY(-10px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}
