/* Add custom styling for code blocks in ReactQuill */
.ql-syntax {
    background-color: #2d2d2d; /* Black background */
    color: #f8f8f2; /* White text */
    padding: 10px;
    border-radius: 5px;
    font-family: 'Courier New', Courier, monospace; /* Monospace font */
    font-size: 14px; /* Font size for code */
    overflow-x: auto; /* Enable horizontal scrolling for long lines */
  }

  /* Styling for ordered and unordered lists inside the editor */
.ql-editor .ql-list {
    padding-left: 1.5em; /* Add left padding for bullets and numbers */
  }
  
  /* Styling for unordered (bullet) lists */
  .ql-editor .ql-list.ql-list-unordered {
    list-style-type: disc; /* Ensure bullet points are shown */
  }
  
  /* Styling for ordered (numbered) lists */
  .ql-editor .ql-list.ql-list-ordered {
    list-style-type: decimal; /* Ensure numbers are shown */
  }
  
  .rendered-content ol {
    list-style-type: decimal; /* For ordered lists */
    margin-left: 20px;
  }
  
  .rendered-content ul {
    list-style-type: disc; /* For unordered lists */
    margin-left: 20px;
  }
  
  .rendered-content li {
    margin-bottom: 5px; /* Adjust the space between list items */
  }

  /* Add this to your CSS file */
.custom-editor h1 {
  font-size: 2.5rem; /* Customize size for h1 */
  font-weight: bold;
  color: #333; /* Example color for h1 */
}

.custom-editor h2 {
  font-size: 2rem; /* Customize size for h2 */
  font-weight: bold;
  color: #555; /* Example color for h2 */
}


/* Target the h1 tag inside the Quill editor */
.ql-editor h1 {
  font-size: 2.5rem; /* Change the font size */
  font-weight: bold; /* Optional: Make it bold */
  color: #333;       /* Optional: Change the color */
  line-height: 1.5;  /* Optional: Adjust line height */
}

/* Optionally, target h2 as well */
.ql-editor h2 {
  font-size: 2rem;   /* Different size for h2 */
  font-weight: bold;
  color: #333;
}

h1 {
  font-size: 2rem; /* Adjust this value as needed */
}

h2 {
  font-size: 1.5rem; /* Adjust this value as needed */
}

h3 {
  font-size: 1rem;
}

p{
  font-size: 16px;
}

ol {
  list-style-type: decimal; /* Ensures numbers are used */
  padding-left: 20px; /* Adds some padding to the left */
}

ul {
  list-style-type: disc; /* Bullets for unordered list */
  padding-left: 20px; /* Padding for unordered list */
  margin: 0; /* Reset margin */
}



  